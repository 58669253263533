<template>
	<div class="mdc-snackbar notice">
		<div class="mdc-snackbar__surface">
			<div class="mdc-snackbar__label"
				role="status"
				aria-live="polite"
			>
				{{ options.message }}
			</div>

			<div class="mdc-snackbar__actions">
				<button type="button" class="mdc-button mdc-snackbar__action" @click="options.action()" v-if="options.action && options.actionButtonText">{{ options.actionButtonText }}</button>
				<button type="button" class="mdc-icon-button mdc-snackbar__dismiss material-icons" title="Dismiss">close</button>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { MDCSnackbar } from '@material/snackbar'

export default Vue.extend({
	name: 'Notice',
	data: () => ({
		snackbar: {} ,
		queued: [],
		defaults: {
			timeoutMs: 10000,
		},
		options: {},
	}),
	mounted() {
		this.snackbar = new MDCSnackbar(this.$el)
		this.snackbar.listen(`MDCSnackbar:closed`, () => {
			this.queued.shift()
			if (this.$el && this.$el.parentNode) {
				this.$el.parentNode.removeChild(this.$el)
			}
		})
	},
	methods: {
		close() {
			this.snackbar.close()
		},
	},
	watch: {
		queued (newVal) {
			if (!newVal.length) return

			this.options = { ...this.defaults, ...this.queued[0] }
			this.snackbar.open()
		},
	},
})
</script>

<style scoped lang="scss">
@import '@material/snackbar/mdc-snackbar';
@import '@material/button/mdc-button';
@import '@material/icon-button/mdc-icon-button';

.mdc-icon-button {
	@include mdc-icon-button-ink-color($mdc-theme-on-primary);
}
</style>
