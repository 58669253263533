<template>
	<div
		class="mdc-dialog"
		role="alertdialog"
		aria-modal="true"
		aria-labelledby="dialog_title"
		aria-describedby="dialog_content"
		:class="options.class"
	>
		<div class="mdc-dialog__container">
			<button type="button" class="mdc-icon-button mdc-dialog__close-button" aria-label="Close Dialog" aria-hidden="false" @click="close()">
				<i class="material-icons mdc-icon-button__icon">close</i>
			</button>
			<div class="mdc-dialog__surface">
				<div class="mdc-dialog__media" v-if="options.media" v-html="options.media">
				</div>
				<div class="mdc-dialog__content" id="dialog_content">
					<h2 class="mdc-dialog__title" id="dialog_title" v-if="options.title">{{ options.title }}</h2>
					<h3 class="mdc-dialog__subtitle" id="dialog_subtitle" v-if="options.subtitle">{{ options.subtitle }}</h3>
					<div class="mdc-dialog__body" v-html="options.message"></div>
				</div>
				<footer class="mdc-dialog__actions">
					<slot name="actions">
						<!-- <button type="button" class="mdc-button mdc-dialog__button" @click="close()">Close</button> -->
					</slot>
				</footer>
			</div>
		</div>
		<div class="mdc-dialog__scrim"></div>
	</div>
</template>

<script>
// Dependencies
import { MDCDialog } from '@material/dialog';

export default {
	name: 'DialogPlugin',
	data: () => ({
		dialog: null,
		queued: [],
		options: {},
	}),
	mounted() {
		this.dialog = new MDCDialog(this.$el)
		const contentDiv = this.$el.querySelector('.mdc-dialog__content')

		if (this.open) {
			this.dialog.open()
		}

		this.dialog.listen('MDCDialog:closed', () => {
			this.queued.shift()
			if (this.$el && this.$el.parentNode) {
				// this.$el.parentNode.removeChild(this.$el)
			}
		})

		this.dialog.listen('MDCDialog:opened', () => {
			if (contentDiv) {
				contentDiv.scrollTop = 0
			}
		})
	},
	methods: {
		close() {
			console.log('close')
			this.dialog.close()
		},
	},
	watch: {
		queued (newVal) {
			if (!newVal.length) return

			this.options = { ...this.defaults, ...this.queued[0] }
			this.dialog.open()
		},
		open(val) {
			if (val) {
				this.dialog.open()
			} else {
				this.dialog.close();
			}
		},
	},
}
</script>

<style scoped lang="scss">
@import '@material/dialog/mdc-dialog';
// @import '@material/button/mdc-button';
@import "@material/icon-button/mdc-icon-button";

.mdc-dialog {
	$dialog: &;
	z-index: 25;

	&__heading {
		position: relative;
	}

	&__container {
		@include breakpoint('mobile') {
			width: 100vw;
		}
	}

	#{$dialog}__surface {
		background-color: transparent;
		box-shadow: none;
		display: flex;
		flex-direction: row;
		min-height: 100vh;
		overflow: visible;
		// min-width: 50vw;
		// max-width: 1000px;

		@include breakpoint('mobile') {
			flex-direction: column;
			z-index: 3;
		}

	}


	&#{$dialog}--our-people,
	&#{$dialog}--leader-message,
	&#{$dialog}--corporate-social,
	&#{$dialog}--investment {
		#{$dialog}__surface {
			// min-width: calc(90vw - 48px);
			max-width: 1150px;
			width: 100%;

			@include breakpoint('mobile') {
				max-width: calc(100vw - 48px);
			}

			@include breakpoint('desktop') {
				max-width: calc(100vw - 96px);
				// max-width: 1150px;
			}
		}

		#{$dialog}__media {
			@include breakpoint('mobile') {
				max-width: none;
			}
		}

	}

	&#{$dialog}--our-people,
	&#{$dialog}--corporate-social {
		@include breakpoint('mobile') {
			text-align: center;
		}

		#{$dialog}__body {
			::v-deep h2 {
				@include breakpoint('mobile') {
					font-size: 18px;
				}
			}
		}
	}

	&#{$dialog}--board-directors {
		#{$dialog}__surface {
			//min-width: calc(50vw - 48px);
			max-width: calc(90vw - 48px);
		}
	}

	// Overwrite
	&#{$dialog}--leader-message {
		#{$dialog}__media {
			@include breakpoint('desktop') {
				padding-bottom: 0;
			}
			::v-deep img {
				@include breakpoint('desktop') {
					max-width: 200px;
				}

				@include breakpoint('mobile') {
					max-width: 150px;
				}
			}
		}
	}

	&#{$dialog}--our-people,
	&#{$dialog}--leader-message,
	&#{$dialog}--corporate-social,
	&#{$dialog}--board-directors {
		#{$dialog}__media {
			@include breakpoint('desktop') {
				padding-bottom: 0;
			}
			::v-deep img {
				@include breakpoint('desktop') {
					max-width: 200px;
				}

				@include breakpoint('mobile') {
					max-width: 150px;
				}
			}
		}
	}

	&#{$dialog}--investment {
		#{$dialog}__title {
			text-align: left;
		}
		#{$dialog}__media {
			@include breakpoint('desktop') {
				padding-bottom: 0;
			}
			::v-deep img {
				@include breakpoint('desktop') {
					max-width: 200px;
					margin: 0 auto;
				}
				@include breakpoint('mobile') {
					max-width: 150px;
				}
			}

			&:after {

			}
		}

		#{$dialog}__body {
			::v-deep h3 {
				font-weight: bold;
				font-size: 20px;
			}
		}
	}



	#{$dialog}__title {
		color: color_('background', 'dark');
		font-family: $font-family-secondary;
		font-size: 36px;
		font-weight: bold;
		padding: 0;
		margin-bottom: 10px;
		border-bottom: 0;

		@include breakpoint('mobile') {
			text-align: center;
		}

		@include breakpoint('mobile') {
			font-size: 28px;
		}

		&:before {
			@include breakpoint('mobile') {
				height: 0;
				top: -5px;
			}
		}
	}

	#{$dialog}__subtitle {
		color: color_('primary');
		font-size: 18px;
		font-style: italic;
		font-weight: bold;
		margin-top: 0;

		@include breakpoint('mobile') {
			text-align: center;
		}
	}

	&__close-button {
		background-color: black;
		color: white;
		position: absolute;
		right: 0;
		transform: translateX(100%);
		top: 0;

		@include breakpoint('mobile') {
			pointer-events: all;
			transform: translateX(0);
			z-index: 12;
		}

	}

	#{$dialog}__scrim {
		background-color: rgba(color_('background', 'dark'), .95);
	}

	&__content {
		background-color: white;
		margin-right: -16px;
		white-space: pre-wrap;
		//padding: 40px 24px;
		@include gutter('padding-top', 2);
		@include gutter('padding-bottom', 2);
		@include gutter('padding-left', 1);
		@include gutter('padding-right',2);
		letter-spacing: 0;

		@include breakpoint('mobile') {
			max-width: 100%;
			margin-right: 0;
		}

		// @include breakpoint('desktop') {
		// 	@include gutter('padding', 1);
		// }

		@include breakpoint('mobile') {
			@include gutter('padding', 1);
		}

		#{$dialog}--investment & {
			white-space: inherit;
		}

		.mdc-dialog--scrollable & {
			//padding: 40px 24px;
			@include gutter('padding-top', 2);
			@include gutter('padding-bottom', 2);
			@include gutter('padding-left', 1);
			@include gutter('padding-right',2);

			// @include breakpoint('desktop') {
			// 	@include gutter('padding', 1);
			// }

			@include breakpoint('mobile') {
				@include gutter('padding', 1);
			}
		}

		blockquote {
			border-left: 5px solid color_('secondary');
			color: color_('primary');
			font-size: 18px;
			font-style: italic;
			font-weight: bold;
			margin-bottom: 1em;
			padding-left: 1em;
		}
	}

	&__body {
		color: color_('text');
		font-weight: 300;
		max-width: 600px;

		@include breakpoint('desktop') {
			max-width: 750px;
		}

		::v-deep .intro {
			color: color_('primary');
			font-size: 1.25rem;
			font-style: italic;
			font-weight: bold;
			line-height: 2rem;
			padding-left: 1rem;
			border-left: 5px solid color_('secondary');

			@include breakpoint('mobile') {
				font-size: 1rem;
				line-height: 1.5rem;
			}
		}

		::v-deep .quote--start,
		::v-deep .quote--end {
			width: 100%;
			height: 50px;
			display: block;
			//background-image: url('~@/assets/quote_start.jpg');
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			margin: 40px 0;

			@include breakpoint('mobile') {
				margin: 20px 0;
			}
		}

		::v-deep img {
			max-width: 100%;
		}

		::v-deep .quote--end {
			//background-image: url('~@/assets/quote_end.jpg');
		}

		::v-deep .headline {
			color: color_('background', 'dark');
			font-family: $font-family-secondary;
			font-size: 36px;
			font-weight: bold;
			padding: 0;
			margin-bottom: 10px;
			border-bottom: 0;
			line-height: 1;

			@include breakpoint('mobile') {
				font-size: 28px;
			}
		}
	}

	#{$dialog}__media {
		@include gutter('padding');
		background: rgb(255,255,255);
		background: linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
		position: relative;
		// margin-left: -20%;
		// transform: translateX(-50%);

		@include breakpoint('mobile') {
			background: linear-gradient(180deg, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
			text-align: center;
			margin-top: 2em;
		}

		::v-deep img {
			border-radius: 50%;
			max-width: 94%;
			// margin-right: -50%;
			// margin-left: -50%;

			@include breakpoint('mobile') {
				// max-width: 630px;
				max-width: 50%;
			}

			// @media screen and (max-height: 780px) and (min-width: 768px) {
			// 	zoom: 50%;
			// }
		}
	}

	&#{$dialog}--support-circle  {
		::v-deep .support-circle__media {
			background-color: color_('secondary');
			width: 150px;
			height: 150px;
			border: 10px solid color_('secondary');
			border-radius: 50%;
			margin-left: auto;
			margin-right: auto;
			padding: 10px;

			img {
				border-radius: 0;
				max-width: 100%;
			}
		}

		#{$dialog}__body {
			@include breakpoint('mobile') {
				text-align: center;
			}
			::v-deep span {
				display: block;
				margin-bottom: 10px;
			}
		}

	}

	::v-deep .investment__circle-wrapper {
    	align-items: center;
		background-color: color_('background', 'dark');
		border-radius: 50%;
		border: 10px solid white;
		display: flex;
		height: 270px;
		justify-content: center;
		max-width: 100% !important;
		padding: 1em;
		transition: transform 150ms $ease-sharp;
		width: 270px;
		z-index: 9;


		.investment__circle {
			border-radius: 0;
			max-width: 140px;
			width: 100%;
		}

		@include breakpoint('mobile') {
			height: auto;
			border: 5px solid white;
			//padding: 1em;
		}

		@include breakpoint('mobile') {
			background-color: color_('background', 'dark');
			border-radius: 50%;
			border: 10px solid white;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 145px;
			height: 145px;
			margin: 0 auto;
			padding: 1em;
		}

		path {
			fill: color_('secondary');
		}

		@media screen and (max-height: 780px) and (min-width: 768px) {
			zoom: 50%;
		}
	}
}
</style>
