<template>
	<div
		class="mdc-dialog"
		role="alertdialog"
		aria-modal="true"
		aria-labelledby="dialog_title"
		aria-describedby="dialog_content"
		:class="options.class"
	>
		<div class="mdc-dialog__container">

			<div class="mdc-dialog__surface">
				<div class="video-container">
					<iframe :src="options.message" frameborder="0" allow="autoplay;fullscreen" allowfullscreen></iframe>
					<button type="button" class="mdc-icon-button mdc-dialog__close-button" aria-label="Close Dialog" aria-hidden="false" @click="close()">
						<i class="material-icons mdc-icon-button__icon">close</i>
					</button>
				</div>
			</div>
		</div>
		<div class="mdc-dialog__scrim"></div>
	</div>
</template>

<script>
// Dependencies
import { MDCDialog } from '@material/dialog';

export default {
	name: 'VideoPlugin',
	data: () => ({
		dialog: null,
		queued: [],
		options: {},
	}),
	mounted() {
		this.dialog = new MDCDialog(this.$el)
		if (this.open) {
			this.dialog.open()
		}
		this.dialog.listen('MDCDialog:closed', () => {
			this.queued.shift()
			if (this.$el && this.$el.parentNode) {
				this.$el.parentNode.removeChild(this.$el)
			}
		})
	},
	methods: {
		close() {
			this.dialog.close()
		},
	},
	watch: {
		queued (newVal) {
			if (!newVal.length) return

			this.options = { ...this.defaults, ...this.queued[0] }
			this.dialog.open()
		},
		open(val) {
			if (val) {
				this.dialog.open()
			} else {
				this.dialog.close();
			}
		},
	},
	metaInfo: () => ({
		script: [
			{
				src: 'https://player.vimeo.com/api/player.js',
				type: 'text/javascript',
			},
		],
	}),
}
</script>

<style scoped lang="scss">
@import '@material/dialog/mdc-dialog';
// @import '@material/button/mdc-button';
@import "@material/icon-button/mdc-icon-button";

.mdc-dialog {
	$dialog: &;
	z-index: 25;

	&__container {
		height: 100%;
		width: 100%;
	}

	&__surface {
		position: relative;
		width: 75%;
	}

	#{$dialog}__surface {
		background-color: transparent;
		box-shadow: none;
	}

	@media (min-width: 592px) {
		#{$dialog}__surface {
			max-width: 100%;
		}
	}

	.video-container {
		@include ratio('film');
		// position: absolute;
		height: 100vh;
		top: 0;

		iframe {
			width: 100%;
			height: 100%;
		}
	}

	&__close-button {
		background-color: black;
		color: white;
		position: absolute;
		right: 0;
		left: auto !important;
		// transform: translateX(100%);
		top: 16px !important;
	}

	#{$dialog}__scrim {
		background-color: rgba(color_('background', 'dark'), 0.8);
	}

}
</style>
