const state = {
	isMobile: false,
}

const actions = {
	detectMobile({ commit }) {
		const mediaQueryMobile = window.matchMedia(`(max-width: 768px)`)
		commit('setIsMobile', mediaQueryMobile.matches)
		mediaQueryMobile.addListener(() => {
			commit('setIsMobile', mediaQueryMobile.matches)
		})

	}
}
const mutations = {
	setIsMobile(state, status) {
		state.isMobile = status
	}
}

const getters = {
	isMobile: state => state.isMobile
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
