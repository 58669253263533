// Dependencies
import Dialog from './Dialog.vue'

// Plugin
export const DialogPlugin = {
	install(Vue) {
		const CONSTRUCTOR = Vue.extend(Dialog)
		const CACHE = {}

		function dialog(message, options = {}) {
			options.id = options.id || 'dialog'
			options.message = message

			const dialog = CACHE[options.id] || (CACHE[options.id] = new CONSTRUCTOR())
			if (!dialog.$el) {
				const vm = dialog.$mount()
				document.querySelector(options.parent || 'body').appendChild(vm.$el)
			}

			dialog.queued.push(options)
		}

		Vue.prototype.$dialog = dialog
	}
}
