<template>
	<transition
		name="fadey"
		mode="in-out"
		>
		<router-view id="app" />
	</transition>
</template>

<script>
// import { smoothScroll } from '@/lib/utils'

export default {
	name: 'App',
	created() {
		document.addEventListener('SWUpdated', this.showRefresh)
	},
	data: () => ({
		// transition: 'fade',
	}),
	methods: {
		showRefresh(evt) {
			this.$notice(`New version available! Please refresh to update.`, () => {
				evt.detail.postMessage({ action: 'skipWaiting' })
			}, {
				actionButtonText: `REFRESH`,
				timeoutMs: 10000,
			})
		}
	},
	computed: {
		transition() {
			const { dir } = this.$route.params
			if (!dir)
				return 'fade'

			return dir == 'right' ? 'out-left' : 'out-right'
			// return 'fade'
		},
		mode() {
			const { dir } = this.$route.params
			if (!dir)
				return 'in-out'

			return undefined
		},
	},
	metaInfo: () => ({
		title: 'Intro',
		titleTemplate: '%s | DTCC 2020 Annual Report',
		meta: [
				{
					charset: 'utf-8',
				},
				{
					httpEquiv: 'X-UA-Compatible',
					content: 'IE-edge',
				},
				{
					name: 'description',
					content: '',
				},
				{
					name: 'viewport',
					content: 'width=device-width, initial-scale=1, maximum-scale=5',
				},
		],
		link: [
			{
				rel: 'stylesheet',
				href: '//fonts.googleapis.com/icon?family=Lato:300,400,400i,700,700i,900,900i|Material+Icons&display=swap',
			},
		],
		script: [
			// Analytics
			// {
			// 	innerHTML: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){ (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o), m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m) })(window,document,'script','https://www.google-analytics.com/analytics.js','ga'); ga('create', 'UA-47330161-1', 'auto'); ga('send', 'pageview');`,
			// 	type: `text/javascript`
			// },
			// //GTM
			// {
			// 	innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-59Q7PV');`,
			// 	type: `text/javascript`
			// },
			// {
			// 	innerHTML: `(function() { var didInit = false; function initMunchkin() { if(didInit === false) { didInit = true; Munchkin.init('669-QIL-921'); } } var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = '//munchkin.marketo.net/munchkin.js'; s.onreadystatechange = function() { if (this.readyState == 'complete' || this.readyState == 'loaded') { initMunchkin(); } }; s.onload = initMunchkin; document.getElementsByTagName('head')[0].appendChild(s); })();`,
			// 	type: `text/javascript`
			// },
		],
		__dangerouslyDisableSanitizers: ['script']
	}),
}
</script>

<style lang="scss">
@import '@/styles/partials';

.zoom {
	@media screen and (max-height: 780px) and (min-width: 768px) {
		zoom: 75%;
	}
}
.fadey-enter-active,
.fadey-leave-active {
	transition-duration: .3s;
	transition-property: opacity;
	transition-timing-function: ease;
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
}

.fadey-enter,
.fadey-leave-active {
  opacity: 0
}


.slide-enter-active,
.slide-leave-active {
	transition: opacity .5s;
}

.slide-enter,
.slide-leave-to {
	opacity: 0;
}


.out-left-enter-active, .out-left-leave-active,
.out-right-enter-active, .out-right-leave-active,
.fade-enter-active, .fade-leave-active {
  transition: all .3s ease;
}

.out-left-leave-to,
.out-right-enter {
  position: absolute;
  transform: translateX(-2.5em);
  opacity: 0;
}

.out-left-enter,
.out-right-leave-to {
  position: absolute;
  transform: translateX(2.5em);
  opacity: 0;
}


// .fade-leave-to,
// .fade-enter-to {
// 	.transition-item {
// 		position: absolute;
// 	}
// }



// .fade-leave-active {
// 	transition-delay: 1050ms;
// }
</style>
