// Dependencies
import Video from './Video.vue'
import { v4 } from 'uuid'

// Plugin
export const VideoPlugin = {
	install(Vue) {
		const CONSTRUCTOR = Vue.extend(Video)
		const CACHE = {}

		function video(message, options = {}) {
			options.id = options.id || v4()
			options.message = message

			const video = CACHE[options.id] || (CACHE[options.id] = new CONSTRUCTOR())
			if (!video.$el) {
				const vm = video.$mount()
				document.querySelector(options.parent || 'body').appendChild(vm.$el)
			}

			video.queued.push(options)
		}

		Vue.prototype.$video = video
	}
}
