// Dependencies
import Notice from './Notice.vue'

// Plugin
export const NoticePlugin = {
	install(Vue) {
		const CONSTRUCTOR = Vue.extend(Notice)
		const CACHE = {}

		function notice(message, action = () => ({}), options = {}) {
			options.id = options.id || 'notice'
			options.message = message
			options.action = action

			const notice = CACHE[options.id] || (CACHE[options.id] = new CONSTRUCTOR())
			if (!notice.$el) {
				const vm = notice.$mount()
				document.querySelector(options.parent || 'body').appendChild(vm.$el)
			}

			notice.queued.push(options)
		}

		Vue.prototype.$notice = notice
	}
}
