import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import { router } from './router'
import { store } from './store'
import { gsap } from 'gsap'

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import { NoticePlugin } from '@/lib/plugins/notice'
Vue.use(NoticePlugin)
import { DialogPlugin } from '@/lib/plugins/dialog'
Vue.use(DialogPlugin)
import { VideoPlugin } from '@/lib/plugins/video'
Vue.use(VideoPlugin)

Vue.config.productionTip = false


Vue.directive('hero-click', {
	bind(el, attr) {
		el.addEventListener('click', evt => {
			if (attr.value == 'disable') return
			let toAnimate = attr.value ? document.querySelector(attr.value) : evt.target
			if (toAnimate) {
				const rect = toAnimate.getBoundingClientRect()
				const tl = gsap.timeline()

				toAnimate.style.left = rect.left + 'px'
				toAnimate.style.top = rect.top + 'px'
				toAnimate.style.height = rect.height + 'px'
				toAnimate.style.width = rect.width + 'px'
				toAnimate.style.zIndex = 99
				tl.to(toAnimate, {
					duration: .3,
					width: '100vw',
					height: '100vh',
					position: 'fixed',
					maxWidth: '100vw',
					top: '0px',
					left: '0px',
					marginLeft: 0,
					marginRight: 0,
					ease: 'circ.out',
				})
				// tl.to(toAnimate, {
				// 	duration: 0.5,
				// 	opacity: 0,
				// })
			}
		})
	},
})

// Media Query Directive
const mediaQueryMobile = window.matchMedia(`(max-width: 768px)`)
const _onMatchMedia = (el, binding, vnode, evt) => {
	if (mediaQueryMobile.matches) {
		if (binding.value == false) {
			el.style.display = 'none'
			el.style.pointerEvents = 'none'
		} else {
			el.style.display = ''
			el.style.pointerEvents = ''
		}
	} else {
		if (binding.value == true) {
			el.style.display = 'none'
			el.style.pointerEvents = 'none'
		} else {
			el.style.display = ''
			el.style.pointerEvents = ''
		}
	}
}

// Scroll Viewport Directive
let _lastEvt
const _onScroll = (el, binding, vnode, evt) => {
	const elBounds = el.getBoundingClientRect()
	_lastEvt = evt
	if ( (elBounds.left < evt.detail.bounds.width) && elBounds.right >= 0) {
		el.classList.add('in-viewport')
		el.classList.add('viewed')
	} else if (elBounds.right < 0) {
		el.classList.remove('in-viewport')
	}
}

let _scrollFn
Vue.directive('viewport', {
	bind: (el, binding, vnode) => {
		if (mediaQueryMobile.matches) return
		requestAnimationFrame(() => {
			window.addEventListener('smoothScroll', _scrollFn = _onScroll.bind(this, el, binding, vnode))
		})
	},
	unbind: (el, binding, vnode) => {
		if (mediaQueryMobile.matches) return
		window.removeEventListener('smoothScroll', _scrollFn)
	},
	update: (el, binding, vnode) => {
		if (mediaQueryMobile.matches) return
		_onScroll(el, binding, vnode, _lastEvt)
	},
})


Vue.directive('is-mobile', {
	bind: (el, binding, vnode) => {
		mediaQueryMobile.addListener(_onMatchMedia.bind(this, el, binding, vnode))
		_onMatchMedia(el, binding, vnode)
	},
	unbind: (el, binding, vnode) => {
		mediaQueryMobile.removeListener(_onMatchMedia)
	},
})

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
