import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// Modules
import modules from './modules'

export const store = new Vuex.Store({
	modules,
})
