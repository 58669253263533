import { gsap } from 'gsap'
import { Flip } from 'gsap/Flip'
gsap.registerPlugin(Flip)

const state = {
	open: false,
	socialOpen: false,
	lastState: null,
	navFrom: null,
	navTo: null,
	items: [
		{
			label: 'Leadership',
			link: {
				hash: 'leadership',
			},
		},
		{
			label: 'Financials',
			link: {
				hash: 'financials',
			},
		},
		{
			label: '2020 Highlights',
			link: {
				hash: 'highlights',
			},
		},
		{
			label: 'Architecting Innovation',
			link: {
				hash: 'innovation',
			},
		},
		{
			label: 'Power of Our People',
			link: {
				hash: 'people',
			},
		},
	],
	// socialLinks: [
	// 	{
	// 		label: 'Twitter',
	// 		icon: require('@/assets/icon__twitter.png'),
	// 		link: 'https://twitter.com/The_DTCC',
	// 	},
	// 	{
	// 		label: 'Linked In',
	// 		icon: require('@/assets/icon__linkedin.png'),
	// 		link: 'https://www.linkedin.com/company/dtcc',
	// 	},
	// 	{
	// 		label: 'Facebook',
	// 		icon: require('@/assets/icon__facebook.png'),
	// 		link: 'https://www.facebook.com/TheDTCC/',
	// 	},
	// 	{
	// 		label: 'YouTube',
	// 		icon: require('@/assets/icon__youtube.png'),
	// 		link: 'https://www.youtube.com/c/thedtcc1973',
	// 	},
	// ],
	// shareLinks: [
	// 	{
	// 		label: 'Twitter',
	// 		icon: require('@/assets/icon__twitter.png'),
	// 		link: 'https://twitter.com/home?status=$status$',
	// 	},
	// 	{
	// 		label: 'LinkedIn',
	// 		icon: require('@/assets/icon__linkedin.png'),
	// 		link: 'https://www.linkedin.com/shareArticle?mini=true&url=$url$',
	// 	},
	// 	{
	// 		label: 'Facebook',
	// 		icon: require('@/assets/icon__facebook.png'),
	// 		link: 'https://www.facebook.com/sharer/sharer.php?u=$url$',
	// 	},
	// 	{
	// 		label: 'Email',
	// 		icon: require('@/assets/icon__email.png'),
	// 		link: 'mailto:recipient?subject=$title$&body=$description$ - $url$',
	// 	},
	// ]
}

const actions = {
	toggle({ commit }, status) {
		return commit('toggleNav', status)
	},
	socialToggle({ commit }, status) {
		return commit('toggleSocialNav', status)
	},
	setLastState({ commit }, el) {
		return commit('setLastState', el)
	},
	setNavFrom({ commit }, el) {
		return commit('setNavFrom', el)
	},
	setNavTo({ commit }, el) {
		return commit('setNavTo', el)
	},
	setNavReverse({ commit }) {
		return commit('setNavReverse')
	}
}

const mutations = {
	toggleNav(state, status) {
		if (typeof status != 'undefined') {
			return state.open = status
		}
		state.open = !state.open
	},
	toggleSocialNav(state, status) {
		if (typeof status != 'undefined') {
			return state.socialOpen = status
		}
		state.socialOpen = !state.socialOpen
	},
	setLastState(state, e) {
console.log('nav.setLastState', state.lastState, e)
		if (!state.lastState) {
			state.lastState = Flip.getState(e)
		} else {
			Flip.from(state.lastState, {
				targets: e,
			})
			state.lastState = null
		}
	},
	setNavFrom(state, e) {
console.log('nav.setFrom', e)
		state.navFrom = Flip.getState(e)
	},
	setNavTo(state, e) {
console.log('nav.setTo', e, state.navFrom)
		state.navTo = e
		if (state.navFrom) {
			Flip.from(state.navFrom, {
				targets: e,
				duration: 6,
				absolute: true,
				simple: true,
			})
			// state.navFrom = null
		}
	},
	setNavReverse(state) {
		if (state.navTo && state.navFrom) {
			Flip.from(state.navTo, {
				targets: state.navFrom,
			})
		}
	},
}

const getters = {
	open: state => state.open,
	socialOpen: state => state.socialOpen,
	items: state => {
		return state.items
	},
	socialLinks: state => state.socialLinks,
	shareLinks: state => state.shareLinks,
	lastState: state => state.lastState,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
