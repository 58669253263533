/**
 * Routes
 */

// Views
const ViewDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue')
const ViewPage = () => import(/* webpackChunkName: "slideshow" */ '@/views/Page.vue')

// Partials
const Highlights = () => import(/* webpackChunkName: "highlights" */ '@/views/partials/Highlights.vue')
const Letters = () => import(/* webpackChunkName: "letters" */ '@/views/partials/Letters.vue')
const Performance = () => import(/* webpackChunkName: "performance" */ '@/views/partials/Performance.vue')
const Pandemic = () => import(/* webpackChunkName: "pandemic" */ '@/views/partials/Pandemic.vue')
const Bios = () => import(/* webpackChunkName: "bios" */ '@/views/partials/Bios.vue')
const Stride = () => import(/* webpackChunkName: "stride" */ '@/views/partials/Stride.vue')
const Videos = () => import(/* webpackChunkName: "videos" */ '@/views/partials/Videos.vue')
const Csr = () => import(/* webpackChunkName: "csr" */ '@/views/partials/Csr.vue')

// Components
//const mediaQueryMobile = window.matchMedia(`(max-width: 768px)`)

// Functions
const mainComponents = (content, modal) => ({
	content,
	modal,
})

// Routes
export const routes = [
	{
		path: '/',
		component: ViewDashboard,
		children: [
			{
				name: 'dashboard',
				path: '',
				meta: {},
			},
		],
	},
	{
		path: '',
		component: ViewPage,
		children: [
			{
				name: 'highlights',
				path: '/business-highlights/:slide?',
				components: mainComponents(Highlights),
			},
			{
				name: 'letters',
				path: '/letters/:letter',
				components: mainComponents(Letters),
			},
			{
				name: 'csr',
				path: '/csr/:article',
				components: mainComponents(Csr),
			},
			{
				name: 'performance',
				path: '/performance/:slide?',
				components: mainComponents(Performance),
			},
			{
				name: 'pandemic',
				path: '/pandemic/:slide?',
				components: mainComponents(Pandemic),
			},
			{
				name: 'bios',
				path: '/bios/:person?',
				components: mainComponents(Bios),
			},
			{
				name: 'stride',
				path: '/stride',
				components: mainComponents(Stride),
			},
			{
				name: 'video',
				path: '/video/:videoId',
				components: mainComponents(Videos),
			}
		],
	},
]
